var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', [_c('validation-observer', {
    ref: "form",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          attrs: {
            "novalidate": ""
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSubmit.apply(null, arguments);
            }
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('LocaleTabs', {
          attrs: {
            "errors": _vm.formErrors,
            "name": "title"
          },
          scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
            return {
              key: "lang".concat(idx),
              fn: function fn(slotScope) {
                return [_c('ValidationProvider', {
                  key: idx,
                  class: slotScope.selectedTab !== idx ? 'hidden' : '',
                  attrs: {
                    "vid": "title.".concat(k),
                    "name": "".concat(i, " Title"),
                    "rules": "required"
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref2) {
                      var errors = _ref2.errors;
                      return [_c('b-form-group', {
                        attrs: {
                          "label": "".concat(i, " Title")
                        }
                      }, [_c('b-form-input', {
                        attrs: {
                          "placeholder": "".concat(i, " Title")
                        },
                        model: {
                          value: _vm.form.title[k],
                          callback: function callback($$v) {
                            _vm.$set(_vm.form.title, k, $$v);
                          },
                          expression: "form.title[k]"
                        }
                      }), _c('small', {
                        staticClass: "text-danger"
                      }, [_vm._v(_vm._s(errors[0]))])], 1)];
                    }
                  }], null, true)
                })];
              }
            };
          })], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-card-text', {
          staticClass: "mb-0"
        }, [_vm._v(" Status ")]), _c('b-form-checkbox', {
          attrs: {
            "checked": "true",
            "value": "active",
            "unchecked-value": "inactive",
            "name": "check-button",
            "switch": ""
          },
          model: {
            value: _vm.form.status,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status", $$v);
            },
            expression: "form.status"
          }
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12 text-right"
          }
        }, [_c('LoadingButton'), _c('b-button', {
          attrs: {
            "type": "reset",
            "variant": "outline-secondary"
          }
        }, [_vm._v(" Reset ")])], 1)], 1)], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }